<template>
    <div>
        <div class="mt-10" :class="$vuetify.breakpoint.mobile ? '' : 'container'">
            <v-row>
                <v-col
                    cols="12"
                    lg="3"
                    md="4"
                    sm="6"
                    class="px-sm-10 d-flex justify-center"
                    v-for="(item, index) in links"
                    :key="index">
                    <div class="text-center">
                        <v-card
                            class="primary home-card d-flex justify-center"
                            flat
                            :to="item.slug"
                        >
                            <v-icon color="secondary" class="home-icon">{{ item.icon }}</v-icon>
                        </v-card>
                        <h3 class="third--text mt-2">{{item.title}}</h3>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            links: [
                {
                    title: "كل الزبائن ",
                    slug: "/all-clients",
                    icon: "groups",
                    renderComponent: true,
                },
                {
                    title: "الزبائن الجدد",
                    slug: "/new-clients",
                    icon: "fi fi-br-users",
                    renderComponent: true,
                },
                {
                    title: "الزبائن المميزين",
                    slug: "/fav-clients",
                    icon: "person_pin",
                    renderComponent: true,
                },                
                {
                    title: "تسجيل يدوي ",
                    slug: "/dialog",
                    icon: "group_add",
                    renderComponent: true,
                },
                {
                    title: "المحظورين ",
                    slug: "/block-clients",
                    icon: "no_accounts",
                    renderComponent: true,
                },
            ],
        };
    },

    methods: {},
};
</script>


<style lang="scss" scoped>
.container {
    width: 70%;
    margin: auto;
}
</style>














